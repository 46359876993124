import { FAQItem } from "../types";

export const faqs: FAQItem[] = [
  // {
  //   question: {
  //     en: "Is there a dress code for the event?",
  //     hr: "Postoji li dress code za događaj?",
  //     he: "האם יש קוד לבוש?"
  //   },
  //   answer: {
  //     en: "Feel free to showcase your personal style while dressing elegantly in semi-formal or formal attire.",
  //     hr: "Slobodno pokažite svoj osobni stil oblačeći se elegantno u poluformalnu ili formalnu odjeću.",
  //     he: "תרגישו חופשי להציג את הסגנון האישי שלכם בלבישה אלגנטית בקוד לבוש חצי רשמי או רשמי."
  //   },
  // },
  {
    question: {
      en: "Can I bring a plus-one?",
      hr: "Mogu li povesti još jednu osobu?",
      he: "אני יכול\\ה להביא אדם נוסף?",
    },
    answer: {
      en: "Of course! we only ask that you indicate that, along with the name of your plus-one, in your RSVP.",
      hr: "Naravno! samo vas molimo da to naznačite, zajedno s imenom vaše pratnje, u vašoj Potvrdi Dolaska.",
      he: "כמובן! אנו רק מבקשים שתציינו זאת, יחד עם שמו\\ה, באישור ההגעה שלכם.",
    },
  },
  {
    question: {
      en: "Will transportation be provided to and from the venue?",
      hr: "Hoće li biti organiziran prijevoz do i od lokacije?",
      he: "האם תהיה הסעה מאורגנת אל וממקום האירוע?",
    },
    answer: {
      en: "Yes, we will be providing transportation to and from the venue. Please indicate in your RSVP if you will be needing transportation. We will let you know the details closer to the date.",
      hr: "Da, osigurat ćemo prijevoz do i od lokacije. Molimo vas da naznačite u svojoj Potvrdi Dolaska trebate li prijevoz. Detalje ćemo vam javiti bliže datumu.",
      he: "כן, נספק הסעה אל וממקום החתונה. אנא ציינו באישור ההגעה שלכם אם תזדקקו להסעה. פרטים נוספים יישלחו בהמשך."
    },
  },
  {
    question: {
      en: "Is there a registry or preferred method for giving gifts?",
      hr: "Postoji li registar ili preferirani način davanja poklona?",
      he: "",
    },
    answer: {
      en: "Having you join us on our special day is incredibly meaningful. However, if you wish to contribute to our honeymoon fund we would be sincerely grateful.",
      hr: "Vaša prisutnost na našem posebnom danu je nevjerojatno značajna. Međutim, ako želite pridonijeti našem medenom mjesecu, bili bismo jako zahvalni.",
      he: "השתתפותכם ביום המיוחד שלנו חשובה מאוד. אם תרצו לתרום לקרן החתונה שלנו, נהיה מאוד מודים.",
    },
  },
  {
    question: {
      en: "Will there be vegetarian/vegan/gluten-free options?",
      hr: "Hoće li biti dostupne opcije za vegetarijance/vegane/bezglutenske opcije?",
      he: "האם ישנם אפשרויות צמחוניות/טבעוניות/ללא גלוטן בארוחת הערב?",
    },
    answer: {
      en: "Yes, please let us know of any dietary restrictions in your RSVP and we will make sure to accommodate them.",
      hr: "Da, molimo vas da nam javite o bilo kakvim prehrambenim ograničenjima u vašoj Potvrdi Dolaska i mi ćemo se pobrinuti da ih uzmete u obzir.",
      he: "כן, במידה וישנם הגבלות או העדפות כלשהן, אנא ציינו זאת באישור ההגעה שלכם."
    },
  },
  {
    question: {
      en: "Are children welcome at the wedding?",
      hr: "Jesu li djeca dobrodošla na vjenčanje?",
      he: "אני יכול\\ה להביא את הילדים שלי?",
    },
    answer: {
      en: "Of course! we only ask that you indicate the number of children and their ages in your RSVP.",
      hr: "Naravno! samo vas molimo da naznačite broj djece i njihove godine u vašoj Potvrdi Dolaska.",
      he: "בהחלט! אנו רק מבקשים שתציינו את מספר הילדים וגילם באישור ההגעה שלכם."
    },
  },
  {
    question: {
      en: "Is there a schedule for the day?",
      hr: "Postoji li raspored za taj dan?",
      he: "יש לוח זמנים?",
    },
    answer: {
      en: "Yes, all relevant information can be found </en>",
      hr: "Da, sve relevantne informacije možete pronaći </>",
      he: "כן, כל המידע הרלוונטי נמצא </>",
    },
  },
  {
    question: {
      en: "Are there any activities or attractions nearby?",
      hr: "Ima li u blizini ikakvih aktivnosti ili atrakcija?",
      he: "",
    },
    answer: {
      en: "Yes, while a Google search will give you a good idea of what's around, you can also find some recommendations </en/exploring>",
      hr: "Da, iako će vam Google pretraživanje dati dobru ideju o tome što se nalazi u blizini, također možete pronaći neke preporuke <Ovdje>",
      he: "",
    },
  },
  {
    question: {
      en: "Is there parking on-site at the venue?",
      hr: "Postoji li parkiralište na lokaciji?",
      he: "האם קיימת חניה במקום האירוע?",
    },
    answer: {
      en: "There are plenty of parking spaces available at the venue.",
      hr: "Na lokaciji ima dovoljno parkirnih mjesta.",
      he: "ישנם מספיק מקומות חניה זמינים במקום.",
    },
  },
  {
    question: {
      en: "What about photos?",
      hr: "A fotografije?",
      he: "",
    },
    answer: {
      en: "While we will have a professional photographer at the event, we would love to see any shots you take on the day, and we'll be providing a QR code for you to be able to upload them to our shared album.",
      hr: "Iako ćemo na događaju imati profesionalnog fotografa, voljeli bismo vidjeti sve fotografije koje napravite tog dana, a mi ćemo vam dati QR kod kako biste ih mogli prenijeti u naš zajednički album.",
      he: "בעוד שיהיה לנו צלם מקצועי באירוע, נהיה שמחים לראות כל תצלומים שתצלמו ביום האירוע, ונספק קוד QR כדי שתוכלו להעלות אותם לאלבום המשותף שלנו.",
    },
  },
  {
    question: {
      en: "Anything I should know about the ceremony?",
      hr: "Ima li nešto što trebam znati o ceremoniji?",
      he: "",
    },
    answer: {
      en: "Respecting both our cultures, the ceremony will be a mix of both, and we hope you enjoy it as much as we will.",
      hr: "Poštujući obje naše kulture, ceremonija će biti mješavina oba, i nadamo se da će vam se svidjeti koliko i nama.",
      he: "בכבוד לשני התרבויות שלנו, הטקס יהיה מערבב שניהם, ואנו מקווים שתהנו ממנו ככל שנהנינו.",
    },
  },
  {
    question: {
      en: "Is there a deadline for RSVPs?",
      hr: "Postoji li rok za potvrdu dolaska?",
      he: "עד מתי למלא את אישור ההגעה?",
    },
    answer: {
      en: "The earlier you can let us know, the better, but we ask that you RSVP by the 21st of May at the latest.",
      hr: "Što prije možete javiti, to bolje, ali molimo da odgovorite najkasnije do 1. travnja.",
      he: "ככל שתוכלו להודיע לנו מוקדם יותר, זה יותר טוב, אך אנו מבקשים שתציינו השתתפות עד ה-21 במאי לפחות.",
    },
  },
  {
    question: {
      en: "Can I bring my pet to the wedding?",
      hr: "Mogu li dovesti svog ljubimca na vjenčanje?",
      he: "",
    },
    answer: {
      en: "We love pets, but unfortunately the venue does not allow them.",
      hr: "Volimo kućne ljubimce, ali nažalost, lokacija ne dopušta njihov dolazak.",
      he: "",
    },
  },
  {
    question: {
      en: "Can guests request specific songs for the music playlist?",
      hr: "Mogu li gosti zatražiti određene pjesme za glazbenu listu?",
      he: "אפשר לבקש שירים מסוימים למסיבה?",
    },
    answer: {
      en: "Of course! please send us any requests you have and we'll make sure to include them in the playlist. ",
      hr: "Naravno! molimo vas pošaljite nam sve zahtjeve koje imate i pobrinut ćemo se da ih uključimo na glazbenu listu.",
      he: "כמובן! אנא שלחו לנו כל בקשה שיש לכם ונוודא לכלול אותם ברשימת ההשמעה.",
    },
  },
];
