import { useLocation } from "react-router-dom";
import { FlowerIcon, flowers } from "../Components/CustomIcon";
import { useRef, useEffect } from "react";
import { useMain } from "../Hooks/useMain";
import { useMainContext } from "../Context/MainContext";
import { CopyItem } from "../types";

const exploreOptions = [
  {
    title: {
      en: "Dürnstein Castle",
      hr: "Dvorac Dürnstein",
      he: "טירת דורנשטיין",
    },
    description: {
      en: "Dürnstein Castle is a historic fortress perched on a hill overlooking the charming town of Dürnstein. The castle offers panoramic views of the Wachau Valley and the Danube River. Visitors can hike up to the castle ruins, enjoying both the historical ambiance and the breathtaking scenery.",
      hr: "Dvorac Dürnstein povijesna je tvrđava smještena na brdu s pogledom na šarmantni grad Dürnstein. Dvorac nudi panoramski pogled na Wachau Valley i rijeku Dunav. Posjetitelji mogu pješačiti do ruševina dvorca, uživajući u povijesnoj atmosferi i zadivljujućem krajoliku.",
      he: "טירת דורנשטיין היא מצודה היסטורית הנמצאת על גבעה המשקיפה על העיירה המקסימה דורנשטיין. הטירה מציעה נוף פנורמי של עמק ואכאו והנהר דנובה. המבקרים יכולים לטייל עד לריסות הטירה, תוך ניהול האווירה ההיסטורית והנופים המרהיבים.",
    },
    image: "/images/explore/durnstein-castle.webp",
  },
  {
    title: {
      en: "Melk Abbey",
      hr: "Melk",
      he: "מנזר מלק",
    },
    description: {
      en: "Melk Abbey is a stunning Benedictine abbey situated on a rocky outcrop overlooking the Danube River. The abbey boasts Baroque architecture, beautiful frescoes, and an extensive library. Visitors can explore the ornate halls, the impressive library, and the picturesque gardens surrounding the abbey.",
      hr: "Melk Abbey je prekrasna benediktinska opatija smještena na stjenovitom izdanku s pogledom na rijeku Dunav. Opatija se ponosi baroknom arhitekturom, prekrasnim freskama i obimnom knjižnicom. Posjetitelji mogu istražiti ukrašene dvorane, impresivnu knjižnicu i slikovite vrtove oko opatije.",
      he: "מנזר מלק הוא מנזר בנדיקטיני מהמם הממוקם על גבעה סלעית המשקיפה על נהר הדנובה. המנזר מתהדר באדריכלות בארוק, פרסקות יפות וספרייה נרחבת. המבקרים יכולים לחקור את האולמות המקושטים, הספרייה המרשימה והגנים הציוריים שמקיפים את המנזר.",
    },
    image: "/images/explore/melk-abbey.webp",
  },
  {
    title: {
      en: "Wachau Valley Vinyards",
      hr: "Vinogradi Wachau Valley",
      he: "כרמים בעמק ואכאו",
    },
    description: {
      en: "The Wachau Valley is renowned for its picturesque vineyards and winemaking traditions. Consider organizing a wine tour for your guests, allowing them to visit local wineries, taste delicious Austrian wines, and learn about the art of winemaking while surrounded by the stunning landscapes of the valley.",
      hr: "Wachau Valley poznata je po slikovitim vinogradima i vinarskim tradicijama. Razmislite o organiziranju vinske ture za svoje goste, omogućujući im posjet lokalnim vinarijama, kušanje ukusnih austrijskih vina i učenje o umjetnosti proizvodnje vina okruženi zadivljujućim krajolicima doline.",
      he: "עמק ואכאו מפורסם בכרמיו הציוריים ובמסורות הייצור שלו. שקול לארגן סיור יין עבור האורחים שלך, מאפשר להם לבקר ביקבים מקומיים, לטעום יינות אוסטריים טעימים וללמוד על אמנות הייצור של היין בסביבה מרהיבה של העמק.",
    },
    image: "/images/explore/vinyards.webp",
  },
  {
    title: {
      en: "Krems Old Town",
      hr: "Stari grad Krems",
      he: "העיר העתיקה של קרמס",
    },
    description: {
      en: "Explore the charming Old Town of Krems, a historic town with cobbled streets, colorful buildings, and a vibrant atmosphere. Visitors can stroll through the medieval streets, visit local shops, and enjoy the mix of architectural styles that reflect the town's rich history.",
      hr: "Istražite šarmantni stari grad Krems, povijesni grad s popločanim ulicama, šarenim zgradama i živahnom atmosferom. Posjetitelji mogu šetati srednjovjekovnim ulicama, posjetiti lokalne trgovine i uživati u mješavini arhitektonskih stilova koji odražavaju bogatu povijest grada.",
      he: "חקור את העיר העתיקה המקסימה של קרמס, עיר היסטורית עם רחובות מרוצפים, בניינים צבעוניים ואווירה דינמית. המבקרים יכולים לטייל ברחובות הביניים, לבקר בחנויות מקומיות וליהנות מהמיקס של סגנונות אדריכליים המשקפים את ההיסטוריה העשירה של העיר.",
    },
    image: "/images/explore/krems-old-town.webp",
  },
  {
    title: {
      en: "Danube River Cruise",
      hr: "Krstarenje rijekom Dunav",
      he: "טיול בנהר הדנובה",
    },
    description: {
      en: "A Danube River cruise is a fantastic way for guests to experience the Wachau Valley's beauty from a different perspective. Cruises offer breathtaking views of vineyards, castles, and charming villages along the riverbanks, providing a relaxing and picturesque excursion.",
      hr: "Krstarenje rijekom Dunav fantastičan je način da gosti dožive ljepotu Wachau Valley iz drugačije perspektive. Krstarenja nude zadivljujuće poglede na vinograde, dvorce i šarmantna sela uz obale rijeke, pružajući opuštajući i slikoviti izlet.",
      he: "טיול בנהר הדנובה הוא דרך נהדרת למבקרים לחוות את יפיו של עמק ואכאו מנקודת מבט שונה. הטיולים מציעים נופים מרהיבים של כרמים, טירות וכפרים מקסימים לאורך שפתות הנהר, מספקים טיול נוח וציורי.",
    },
    image: "/images/explore/river-cruise.webp",
  },
  {
    title: {
      en: "Aggstein Castle",
      hr: "Dvorac Aggstein",
      he: "טירת אגשטיין",
    },
    description: {
      en: "Aggstein Castle is a medieval fortress perched high above the Danube River. Guests can explore the ruins, enjoy panoramic views, and learn about the castle's fascinating history. The castle also hosts events and exhibitions, adding an extra layer of cultural richness to the experience.",
      hr: "Aggstein Castle srednjovjekovna je tvrđava smještena visoko iznad rijeke Dunav. Gosti mogu istražiti ruševine, uživati u panoramskim pogledima i saznati o fascinantnoj povijesti dvorca. Dvorac također domaćin je događanja i izložbi, dodajući dodatni sloj kulturnog bogatstva iskustvu.",
      he: "טירת אגשטיין היא מבצר ביניים הממוקם בגובה מעל נהר הדנובה. האורחים יכולים לחקור את הריסות, ליהנות מנופים פנורמיים וללמוד על ההיסטוריה המרתקת של המבצר. המבצר מארח גם אירועים ותערוכות, הוספת שכבת עושר תרבותי לחוויה.",
    },
    image: "/images/explore/aggstein-castle.webp",
  },
  {
    title: {
      en: "Wachau Cycle Path",
      hr: "Biciklistička staza Wachau",
      he: "נתיב האופניים של ואכאו",
    },
    description: {
      en: "For active guests, the Wachau Cycle Path offers a scenic route along the Danube River. Rent bikes and ride through vineyards, charming villages, and riverside trails, providing a healthy and enjoyable way to experience the beauty of the Wachau Valley.",
      hr: "Za aktivne goste, Wachau Cycle Path nudi slikovitu rutu uz rijeku Dunav. Najam bicikala i vožnja kroz vinograde, šarmantna sela i staze uz rijeku pružaju zdrav i ugodan način doživljavanja ljepote Wachau Valley.",
      he: "לאורחים פעילים, נתיב האופניים של ואכאו מציע מסלול ציורי לאורך נהר הדנובה. השכירו אופניים ורכבו בין כרמים, כפרים מקסימים ושבילי נהר, מספקים דרך בריאה ונעימה לחוות את יפיו של עמק ואכאו.",
    },
    image: "/images/explore/cycle-path.webp",
  },
  {
    title: {
      en: "Schloss Artstetten",
      hr: "Dvorac Artstetten",
      he: "טירת ארטשטטן",
    },
    description: {
      en: "Schloss Artstetten is a picturesque castle located near the town of Artstetten. The castle houses a museum dedicated to Archduke Franz Ferdinand, providing insight into the life of the Habsburg archduke. The surrounding gardens offer a peaceful setting for a leisurely stroll.",
      hr: "Schloss Artstetten slikoviti je dvorac smješten u blizini grada Artstetten. Dvorac je dom muzeja posvećenog nadvojvodi Franji Ferdinadu, pružajući uvid u život Habsburškog nadvojvode. Okolni vrtovi pružaju mirno okruženje za opuštenu šetnju.",
      he: "טירת ארטשטטן היא טירה ציורית הממוקמת ליד העיירה ארטשטטן. הטירה מארחת מוזיאון מוקדש לארכידוכס פרנץ פרדיננד, המספק תובנות לחייו של ארכידוכס האבסבורג. הגנים הסובבים מציעים סביבה שלווה לטיול מרגיע.",
    },
    image: "/images/explore/schloss-artstetten.webp",
  },
  {
    title: {
      en: "Wachau World Heritage Trail",
      hr: "Staza svjetske baštine Wachau",
      he: "נתיב המורשת העולמית",
    },
    description: {
      en: "The Wachau World Heritage Trail is a hiking trail that takes visitors through the heart of the Wachau Valley, passing by vineyards, orchards, and charming villages. The trail offers breathtaking views of the Danube and the surrounding landscapes, making it an ideal activity for nature enthusiasts.",
      hr: "Wachau World Heritage Trail je planinarska staza koja vodi posjetitelje kroz srce Wachau Valley, prolazeći kroz vinograde, voćnjake i šarmantna sela. Staza nudi zadivljujuće poglede na Dunav i okolne krajolike, čineći je idealnom aktivnošću za ljubitelje prirode.",
      he: "נתיב המורשת העולמית של ואכאו הוא מסלול טיולים שמביא את המבקרים דרך לב העמק ואכאו, עובר בין כרמים, פרדות וכפרים מקסימים. המסלול מציע נופים מרהיבים של הדנובה והנופים הסובבים, ומהווה פעילות אידיאלית לחובבי הטבע.",
    },
    image: "/images/explore/wh-trail.webp",
  },
  {
    title: {
      en: "Wachau Apricot Orchard",
      hr: "Voćnjak marelica Wachau",
      he: "שדות המשמש של ואכאו",
    },
    description: {
      en: "The Wachau Valley is famous for its apricots. During the apricot season, visitors can explore local orchards, participate in apricot picking, and indulge in delicious apricot-based products such as jams, schnapps, and desserts.",
      hr: "Wachau Valley poznata je po marelicama. Tijekom sezone marelica, posjetitelji mogu istražiti lokalne voćnjake, sudjelovati u branju marelica i uživati u ukusnim proizvodima na bazi marelica poput džemova, šnapsa i deserata.",
      he: "עמק ואכאו מפורסם במרקוואים שלו. במהלך עונת המרקוואים, המבקרים יכולים לחקור פרדות מקומיות, להשתתף בקטיף מרקוואים וליהנות ממוצרים טעימים המבוססים על מרקוואים כמו ריבות, שנאפס וקינוחים.",
    },
    image: "/images/explore/orchard.webp",
  },
  {
    title: {
      en: "Jauerling Panorama Road",
      hr: "Panoramska cesta Jauerling",
      he: "כביש הפנורמה של יאורלינג",
    },
    description: {
      en: "For a scenic drive, consider the Jauerling Panoramastrasse. This panoramic road takes guests to the top of Jauerling Mountain, offering breathtaking views of the Wachau Valley, the Danube River, and the surrounding hills. It's a great option for those who prefer a leisurely drive with stunning vistas.",
      hr: "Za slikovitu vožnju, razmislite o Jauerling Panoramastrasse. Ova panoramska cesta vodi goste na vrh planine Jauerling, pružajući zadivljujuće poglede na Wachau Valley, rijeku Dunav i okolne brežuljke. To je odlična opcija za one koji preferiraju opuštenu vožnju s prekrasnim vidicima.",
      he: "לנסיעה ציורית, שקול את Jauerling Panoramastrasse. כביש הפנורמי הזה מביא את האורחים לראש הר הייאורלינג, המציע נופים מרהיבים של עמק ואכאו, נהר הדנובה והגבעות הסובבות. זו אפשרות נהדרת למי שמעדיף נסיעה מרגיעה עם נופים מהממים.",
    },
    image: "/images/explore/jauerling.webp",
  },
  {
    title: {
      en: "Spitz Old Town",
      hr: "Stari grad Spitz",
      he: "העיר העתיקה של ספיץ",
    },
    description: {
      en: "Explore the charming old town of Spitz, known for its well-preserved medieval architecture. Don't miss the Tausendeimerhaus, a historic building with a unique wine barrel capacity of 1,000 Eimer (buckets). The town is also an excellent starting point for leisurely walks along the Danube.",
      hr: "Istražite šarmantni stari grad Spitz, poznat po dobro očuvanoj srednjovjekovnoj arhitekturi. Ne propustite Tausendeimerhaus, povijesnu zgradu s jedinstvenim kapacitetom vinskog bačve od 1.000 Eimer (kanti). Grad je također odlično polazište za opuštene šetnje uz Dunav.",
      he: "חקור את העיר העתיקה המקסימה של ספיץ, הידועה באדריכלותה הביניים המשומרת היטב. אל תחמיצו את Tausendeimerhaus, בניין היסטורי עם נפח חרוט חדשני של 1,000 איימר (דליים). העיר היא גם נקודת התחלה מצוינת לטיולים מרגיעים לאורך נהר הדנובה.",
    },
    image: "/images/explore/spitz-old-town.webp",
  },
  {
    title: {
      en: "Ferdinandswarte Lookout",
      hr: "Loiben Lookout",
      he: "תצפית לויבן",
    },
    description: {
      en: "The Loiben Lookout offers a fantastic vantage point to enjoy panoramic views of the Danube River and the Wachau Valley. It's a relatively short hike, and the reward is a stunning perspective of the picturesque landscapes.",
      hr: "Loiben Lookout nudi fantastičnu točku promatranja za uživanje u panoramskom pogledu na rijeku Dunav i Wachau Valley. To je relativno kratka šetnja, a nagrada je zadivljujuća perspektiva slikovitih krajolika.",
      he: "נקודת המבט של לויבן מציעה נקודת תצפית מצוינת ליהנות מנוף פנורמי של נהר הדנובה ושל עמק ואכאו. זו הליכה יחסית קצרה, והפרס הוא נקודת מבט מרהיבה של הנופים הציוריים.",
    },
    image: "/images/explore/ferdinand-warte.webp",
  },
  {
    title: {
      en: "Kittenberger Gardens",
      hr: "Vrtovi Kittenberger",
      he: "גני קיטנברגר",
    },
    description: {
      en: "Ideal for families or those seeking a whimsical experience, the Kittenberger Adventure Gardens in Schiltern offer beautifully landscaped gardens, themed areas, and interactive exhibits. It's a delightful place for a leisurely stroll and moments of relaxation.",
      hr: "Idealno za obitelji ili one koji traže čarobno iskustvo, Kittenberger Adventure Gardens u Schilternu nude prekrasno uređene vrtove, tematske prostore i interaktivne izložbe. To je divno mjesto za opuštenu šetnju i trenutke opuštanja.",
      he: "אידיאלי למשפחות או למי שמחפש חוויה קסומה, גני ההרפתקאות של קיטנברגר בשילטרן מציעים גנים מקסימים, אזורים עם נושאים ותערוכות אינטראקטיביות. זהו מקום מקסים לטיול מרגיע ורגעי הרפיה.",
    },
    image: "/images/explore/kittenberger.webp",
  },
  {
    title: {
      en: "Therme Laa - Silent Spa",
      hr: "Therme Laa - Silent Spa",
      he: "Therme Laa - Silent Spa",
    },
    description: {
      en: "Treat yourself to a rejuvenating wellness retreat in the serene surroundings of the Wachau Valley. From spa treatments and yoga sessions to meditation workshops and nature walks, there are plenty of opportunities for relaxation, rejuvenation, and self-care.",
      hr: "Počastite se obnavljajućim wellness odmorom u mirnom okruženju Wachau Valley. Od tretmana u spa centru i satova joge do radionica meditacije i šetnji prirodom, pruža se mnogo prilika za opuštanje, obnavljanje i brigu o sebi.",
      he: "תפנקו בסוגיות ריפויות מחדשות בסביבה שלווה של עמק ואכאו. מטיפולי ספא ושיעורי יוגה עד סדנאות מדיטציה וטיולים בטבע, יש המון הזדמנויות להירגעות, ריענון וטיפוח עצמי.",
    },
    image: "/images/explore/silent-spa.webp",
  },
];
export const Exploring = () => {
  const wrapper = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { lang } = useMainContext();

  const copy: CopyItem = {
    SUPER: {
      en: "Plan Your",
      hr: "Planirajte svoj",
      he: "אטרקציות באיזור",
    },
    TITLE: {
      en: "Exploring",
      hr: "Obilazak",
      he: "טיול בווכאו",
    },
    SUBTITLE: {
      en: "The Wachau Valley is known for its stunning landscapes and picturesque vineyards, has a rich history and beautiful scenery overlooking the Danube River. For those planning to stay and explore the area, we have compiled a list of activities and attractions to help you make the most of your visit.",
      hr: "Wachau Dolina poznata je po svojim slikovitim vinogradima, bogatom poviješću i prekrasnim krajolicima s pogledom na Dunav. Za one koji planiraju boraviti i istraživati područje, sastavili smo popis aktivnosti i atrakcija kako biste što bolje iskoristili svoj posjet.",
      he: "עמק הווכאו ידוע בנופיו המהממים והכרמים הציוריים שבו, יש לו היסטוריה עשירה והוא ממוקם על נהר הדנובה.",
    },
  };
  useEffect(() => {
    if (wrapper.current) {
      wrapper.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [wrapper, location]);

  return (
    <div
      ref={wrapper}
      className="relative bg-main-light md:mt-10 pb-4 px-0 sm:px-6 lg:pb-28 lg:px-8"
    >
      <div className="relative max-w-[90%] md:max-w-4xl mx-auto border-t pt-12">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="w-full flex items-center justify-center mb-2">
            <FlowerIcon icon={flowers["F2"]} />
          </div>
          <div className="mt-2 w-full">
            <span className="superheadline text-[16px] md:text-xl ltr:font-davidlibre rtl:font-bonanova w-max mx-auto text-main-yellow flex items-center relative uppercase">
              {copy.SUPER[lang]}
            </span>
          </div>
          <h2 className="mt-4 text-4xl md:text-5xl ltr:font-davidlibre rtl:font-bonanova font-medium">
            <span className="block text-main-green">{copy.TITLE[lang]}</span>
          </h2>
          <div className="my-7 px-8 md:px-0 w-full md:w-[440px] mx-auto text-center text-main text-lg">
            {copy.SUBTITLE[lang]}
          </div>
        </div>
      </div>
      <div className="mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4 lg:max-w-7xl lg:px-8 mt-16">
        <ol className="-mx-3 grid grid-cols-1 gap-y-10 lg:grid-cols-3 lg:text-center xl:-mx-12">
          {exploreOptions.map((option, index) => (
            <li className="relative grid auto-rows-min grid-cols-1 items-center gap-8 px-3 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-1 xl:px-12">
              <div className="relative h-48 overflow-hidden rounded-lg shadow-lg sm:h-60 lg:h-40">
                <div className="absolute inset-0 flex items-center justify-center bg-[radial-gradient(#2C313D_35%,#000)]">
                  <img alt="" src={option.image} />
                </div>
              </div>
              <div className="ltr:text-left rtl:text-right">
                <h3 className="text-2xl">{option.title[lang]}</h3>
                <p className="mt-2 text-[16px] text-slate-700 text-justify">
                  {option.description[lang]}
                </p>
              </div>
              {/* <div className="absolute bottom-0 left-3 xl:left-12 viewmore mt-auto mb-1 mx-auto text-center text-main/90 hover:text-main/80 cursor-pointer text-base">
                View More
              </div> */}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};
