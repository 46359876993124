import clsx from 'clsx';

export const LoadingSpinner = ({ className }: { className?: string }) => {
  return (
    <img
      src='/images/Loading.gif'
      alt='loading'
      width='20'
      height='20'
      className={clsx('h-4 w-4', className)}
    />
  );
};
