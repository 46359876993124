import { DietaryRestriction } from "../types";

export const dietaryRestrictions: DietaryRestriction[] = [
  {
    id: 1,
    name: "Vegetarian",
    title: {
      en: "Vegetarian",
      hr: "Vegetarijanska",
      he: "צמחוני",
    },
  },
  {
    id: 2,
    name: "Vegan",
    title: {
      en: "Vegan",
      hr: "Veganska",
      he: "טבעוני",
    },
  },
  {
    id: 3,
    name: "Gluten-free",
    title: {
      en: "Gluten-free",
      hr: "Bez glutena",
      he: "ללא גלוטן",
    },
  },
  {
    id: 4,
    name: "Kosher",
    title: {
      en: "Kosher",
      hr: "Košer",
      he: "כשר",
    },
  },
  {
    id: 5,
    name: "Halal",
    title: {
      en: "Halal",
      hr: "Halal",
      he: "חלאל",
    },
  },
  {
    id: 6,
    name: "Pescaterian",
    title: {
      en: "Pescaterian",
      hr: "Peskatarian",
      he: "פסקטריאן",
    },
  },
  {
    id: 7,
    name: "Lactose-free",
    title: {
      en: "Lactose-free",
      hr: "Bez laktoze",
      he: "ללא לקטוז",
    },
  },
  {
    id: 8,
    name: "Nut allergy",
    title: {
      en: "Nut allergy",
      hr: "Alergija na orašaste plodove",
      he: "אלרגיה לאגוזים",
    },
  },
];