import "react-slideshow-image/dist/styles.css";
import { Parallax } from "react-parallax";
import { Fade } from "react-slideshow-image";

const images = [
  "/images/slider00.jpg",
  "/images/slider1.jpg",
  "/images/slider2.jpg",
];

export const Slider = () => {
  return (
    <div>
      <Fade arrows={false}>
        {images.map((fadeImage, index) => (
          <div key={index}>
            <Parallax
              bgImage={fadeImage}
              bgImageStyle={{ objectFit: "cover" }}
              bgImageAlt="the cat"
              strength={200}
            >
              <div style={{ height: "520px" }} />
            </Parallax>
          </div>
        ))}
      </Fade>
    </div>
  );
};
