import clsx from "clsx";
import { CopyItem } from "../types";
import { Fragment } from "react";
import { useBreakpoint } from "../Hooks/useBreakpoint";
import { useMainContext } from "../Context/MainContext";
import { Popover, Transition } from "@headlessui/react";
import { Bars2Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink, useLocation } from "react-router-dom";

export const Menu = () => {
  const { lang } = useMainContext();
  const location = useLocation();
  const locationPath = location.pathname.split("/")[2];

  const navigation = [
    {
      id: 1,
      en: "Wedding Day",
      hr: "Dan Vjenčanja",
      he: "יום החתונה",
      to: "/",
    },
    {
      id: 2,
      en: "Arriving",
      hr: "Dolazak",
      he: "הגעה",
      to: "/arriving",
    },
    {
      id: 3,
      en: "Staying",
      hr: "Smještaj",
      he: "שהייה",
      to: "/staying",
    },
    {
      id: 4,
      en: "Exploring",
      hr: "Zanimljivosti",
      he: "טיול",
      to: "/exploring",
    },
    {
      id: 5,
      en: "Q & A",
      hr: "Pitanja",
      he: "מידע נוסף",
      to: "/faq",
    },
    {
      id: 6,
      en: "RSVP",
      hr: "Potvrda Dolaska",
      he: "אישור הגעה",
      to: "/rsvp",
    },
  ];

  return (
    <Popover as="header" className="relative z-50">
      <div className="pt-6 font-bonanova">
        <nav
          className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
          aria-label="Global"
        >
          <div className="flex items-center flex-1">
            <div className="flex items-center justify-between w-full md:w-auto">
              <div className="-mr-2 flex items-center md:hidden justify-center w-full">
                <Popover.Button className="bg-gray-100 rounded-md p-2 inline-flex items-center justify-center text-gray-900 hover:bg-gray-200 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  <Bars2Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="hidden ltr:space-x-8 rtl:gap-8 md:flex md:mx-auto">
              {navigation.map((item) => (
                <NavLink
                  key={item.id}
                  to={`/${lang}${item.to}`}
                  className={clsx(
                    "border-b-2 border-b-transparent text-[16px] font-medium text-gray-700 hover:text-gray-800 hover:border-b-main/70 cursor-pointer",
                    locationPath === item.to.split("/")[1]
                      ? "text-main-yellow hover:text-main-yellow/70"
                      : "text-gray-700 hover:text-gray-800"
                  )}
                >
                  {item[lang]}
                </NavLink>
              ))}
            </div>
          </div>
        </nav>
        <div className="mt-8 relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6">
          <div className="flex items-center flex-1">
            <div className="hidden ltr:space-x-8 md:flex md:mx-auto" dir="ltr">
              {["en", "hr", "he"].map((language) => (
                <NavLink
                  key={language}
                  className={clsx(
                    "border-b-2 border-b-transparent text-[16px] font-medium hover:border-b-main/70 cursor-pointer",
                    language === lang
                      ? "text-main-yellow hover:text-main-yellow/70"
                      : "text-gray-700 hover:text-gray-800"
                  )}
                  to={`/${language}${locationPath ? `/${locationPath}` : ""}`}
                >
                  {language === "he" ? "עברית" : language.toUpperCase()}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="fixed top-0 inset-x-0 p-2 transition transform origin-top md:hidden"
        >
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden font-bonanova">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="pt-5 pb-4">
              <div className="px-2 space-y-1">
                {navigation.map((item) => (
                  <NavLink
                    key={item.id}
                    to={`/${lang}${item.to}`}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                  >
                    {item[lang]}
                  </NavLink>
                ))}
              </div>
              <div
                className="px-2 ltr:space-x-8 flex mx-auto justify-center w-full border-t border-t-main/10 pt-4"
                dir="ltr"
              >
                {["en", "hr", "he"].map((language) => (
                  <NavLink
                    key={language}
                    className={clsx(
                      "border-b-2 border-b-transparent text-[16px] font-medium hover:border-b-main/70 cursor-pointer",
                      language === lang
                        ? "text-main-yellow hover:text-main-yellow/70"
                        : "text-gray-700 hover:text-gray-800"
                    )}
                    to={`/${language}${locationPath ? `/${locationPath}` : ""}`}
                  >
                    {language === "he" ? "עברית" : language.toUpperCase()}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export const Header = () => {
  const { lang } = useMainContext();
  const { size, screen } = useBreakpoint();
  const targetDate = "2024-05-30T14:00:00";
  const daysRemaining = Math.floor(
    (new Date(targetDate).getTime() - new Date().getTime()) /
      (1000 * 60 * 60 * 24)
  );

  const copy: CopyItem = {
    DATE: {
      en: "May 30, 2024 • Wachau, Austria",
      hr: "30. svibnja 2024. • Wachau, Austrija",
      he: "30 במאי 2024 • עמק הווכאו, אוסטריה",
    },
    DAYS: {
      en: `${daysRemaining + 1} Days to Go!`,
      hr: `${daysRemaining + 1} dana do vjenčanja!`,
      he: `${daysRemaining + 1} ימים עד לחתונה!`,
    },
  };
  return (
    <div className="relative overflow-visible z-50 h-screen md:h-auto">
      <div className="relative overflow-visible max-h-[120px] z-50">
        <img src="/images/hero-bg.png" className="w-full" alt="hero-bg" />
      </div>
      <div className="relative block px-[4%] pb-[10px] text-center overflow-hidden">
        <h1 className="font-galdeano text-main-green text-[52px] uppercase tracking-widest pt-16 md:pt-48 2xl:pt-72 3xl:pt-80 pb-5">
          Moish {screen === "mobile" ? <br /> : <></>}&
          {screen === "mobile" ? <br /> : <></>} Petra
        </h1>
        <div className="my-2 ltr:font-garamond rtl:ltr:font-davidlibre rtl:font-bonanova text-main text-[16px] md:text-[18px] uppercase tracking-[1px]">
          {copy.DATE[lang]}
        </div>
        <div className="my-2 ltr:font-garamond rtl:ltr:font-davidlibre rtl:font-bonanova text-main text-[16px] md:text-[18px] uppercase tracking-[1px]">
          {copy.DAYS[lang]}
        </div>
      </div>
      <Menu />
    </div>
  );
};
