import { ReactNode, createContext, useContext } from "react";
import { MainContextProps, Lang } from "../types";
import { useMain } from "../Hooks/useMain";

export const MainContext = createContext<MainContextProps | undefined>(
  undefined
);

export const useMainContext = () => {
  const context = useContext(MainContext);

  if (context === undefined) {
    throw new Error("useMainContext must be used within a MainContextProvider");
  }

  return context;
};

export const MainContextProvider = ({
  lang,
  children,
}: {
  lang: Lang;
  children: ReactNode;
}) => {
  const value = useMain({ lang });
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};
