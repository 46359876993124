import { Disclosure, Transition } from "@headlessui/react";
import { FlowerIcon, flowers } from "../Components/CustomIcon";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { faqs } from "../Data/faqs";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useMainContext } from "../Context/MainContext";
import { CopyItem } from "../types";

export const FAQ = () => {
  const wrapper = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { lang } = useMainContext();

  const copy: CopyItem = {
    SUPER: {
      en: "Frequently Asked",
      hr: "Često Postavljana",
      he: "שאלות נפוצות",
    },
    TITLE: {
      en: "Q & A",
      hr: "Pitanja i Odgovori",
      he: "שאלות ותשובות",
    },
    SUBTITLE: {
      en: "Below is a list of everything we could think of that you might want to know. Feel free to contact us for any information that may not be covered here.",
      hr: "Ispod je popis svega čega bismo se mogli sjetiti, a što biste možda željeli znati. Slobodno nas kontaktirajte za sve informacije koje možda nisu ovdje pokrivene.",
      he: "נשמח לעזור בכל שאלה שיש לכם ואולי לא כללנו כאן.",
    },
  };

  useEffect(() => {
    if (wrapper.current) {
      wrapper.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [wrapper, location]);

  return (
    <div
      ref={wrapper}
      className="relative bg-main-light md:mt-10 pb-4 px-0 sm:px-6 lg:pb-28 lg:px-8"
    >
      <div className="relative max-w-[90%] md:max-w-4xl mx-auto border-t pt-12">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="w-full flex items-center justify-center mb-2">
            <FlowerIcon icon={flowers["F3"]} />
          </div>
          <div className="mt-2 w-full">
            <span className="superheadline text-[16px] md:text-xl ltr:font-davidlibre rtl:font-bonanova w-max mx-auto text-main-yellow flex items-center relative uppercase">
              {copy.SUPER[lang]}
            </span>
          </div>
          <h2 className="mt-4 text-4xl md:text-5xl ltr:font-davidlibre rtl:font-bonanova font-medium">
            <span className="block text-main-green">{copy.TITLE[lang]}</span>
          </h2>
          <div className="my-7 px-8 md:px-0 w-full md:w-[440px] mx-auto text-center text-main text-lg">
            {copy.SUBTITLE[lang]}
          </div>
        </div>
      </div>
      <div className="relative max-w-[90%] md:max-w-4xl mx-auto pt-12">
        <div className="mx-auto w-full max-w-2xl rounded-2xl bg-white p-2">
          {faqs
            .filter((f) => f.question[lang] !== "")
            .map((faq, index) => (
              <Disclosure key={index} as="div" className="mt-2 first:mt-0">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="ltr:font-davidlibre rtl:font-bonanova transition-all flex w-full items-center justify-between rounded-lg bg-main-tint px-4 py-2 ltr:text-left rtl:text-right text-[18px] font-medium text-main hover:bg-main-yellow/15 focus:outline-none focus-visible:ring">
                      <span className="w-[90%]">{faq.question[lang]}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-main-green transition-transform`}
                      />
                    </Disclosure.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="px-4 pb-2 pt-4 text-[16px] text-gray-00">
                        {/* {faq.answer[lang]} */}
                        {faq.answer[lang].split(/(<\/?\w+\/?\w*>)/).map((part, index) => {
                          if (part.startsWith("<") && part.endsWith(">")) {
                            const linkText = part.substring(2, part.length - 1);
                            return (
                              <a key={index} href={`/${linkText}`} className="underline">
                                here.
                              </a>
                            );
                          } else {
                            return part;
                          }
                        })}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ))}
        </div>
      </div>
    </div>
  );
};
