import { useEffect, useState } from "react";
import { CopyItem, TimeLeft } from "../types";
import { useMainContext } from "../Context/MainContext";

const targetDate = "2024-05-30T14:00:00";

export const Countdown = () => {
  const { lang } = useMainContext();
  const copy: CopyItem = {
    DAYS: {
      en: "Days",
      hr: "dana",
      he: "ימים",
    },
    HOURS: {
      en: "Hours",
      hr: "sati",
      he: "שעות",
    },
    MINUTES: {
      en: "Minutes",
      hr: "minute",
      he: "דקות",
    },
    SECONDS: {
      en: "Seconds",
      hr: "sekunde",
      he: "שניות",
    },
  };
  
  const calculateTimeLeft = (): TimeLeft => {
    const difference = new Date(targetDate).getTime() - new Date().getTime();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div>
      {timeLeft.days !== undefined && (
        <div className="grid grid-cols-4 divide-x divide-x-black/10">
          <div className="flex items-center justify-center gap-2 flex-col">
            <div className="ltr:font-davidlibre rtl:font-bonanova text-2xl md:text-5xl text-main-green">
              {timeLeft.days}
            </div>
            <div className="mt-1 text-[16px] md:text-lg text-main">
              {copy.DAYS[lang]}
            </div>
          </div>
          <div className="flex items-center justify-center gap-2 flex-col">
            <div className="ltr:font-davidlibre rtl:font-bonanova text-2xl md:text-5xl text-main-green">
              {timeLeft.hours}
            </div>
            <div className="mt-1 text-[16px] md:text-lg text-main">
              {copy.HOURS[lang]}
            </div>
          </div>{" "}
          <div className="flex items-center justify-center gap-2 flex-col">
            <div className="ltr:font-davidlibre rtl:font-bonanova text-2xl md:text-5xl text-main-green">
              {timeLeft.minutes}
            </div>
            <div className="mt-1 text-[16px] md:text-lg text-main">
              {copy.MINUTES[lang]}
            </div>
          </div>{" "}
          <div className="flex items-center justify-center gap-2 flex-col">
            <div className="ltr:font-davidlibre rtl:font-bonanova text-2xl md:text-5xl text-main-green">
              {timeLeft.seconds}
            </div>
            <div className="mt-1 text-[16px] md:text-lg text-main">
              {copy.SECONDS[lang]}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
