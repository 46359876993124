import { Disclosure, Transition } from "@headlessui/react";
import { FlowerIcon, flowers } from "../Components/CustomIcon";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useMainContext } from "../Context/MainContext";
import { CopyItem, Lang } from "../types";

export const Arriving = () => {
  const wrapper = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { lang } = useMainContext();

  const copy: CopyItem = {
    SUPER: {
      en: "Plan Your",
      hr: "Planirajte Vaš",
      he: "איך מגיעים",
    },
    TITLE: {
      en: "Arrival",
      hr: "Dolazak",
      he: "הגעה",
    },
    SUBTITLE: {
      en: "We want to make sure you have a smooth arrival to the wedding. Below you'll find an outline of all possible ways to reach the venue hassle-free. All durations are using Vienna as the point of departure.",
      hr: "Želimo se pobrinuti da imate glatki dolazak na vjenčanje. Ispod ćete pronaći pregled svih mogućih načina dolaska do lokacije bez stresa. Sve su duljine putovanja izračunate iz Beča kao polazišne točke.",
      he: "כאן תמצאו סקירה של כל הדרכים האפשריות להגעה לאירוע. כל האפשרויות מחשיבות את וינה כנקודת היציאה.",
    },
  };

  const options: {
    title: Record<Lang, string>;
    content: Record<Lang, string>;
    open?: boolean;
  }[] = [
    {
      title: {
        en: "By Car",
        hr: "Automobilom",
        he: "ברכב",
      },
      content: {
        en: "Arriving by car is the most convenient way to reach the venue. The drive from Vienna takes approximately 1 hour and 10 minutes, depending on traffic. The venue has a parking lot with plenty of space for all guests.",
        hr: "Dolazak automobilom je najpogodniji način dolaska do lokacije. Vožnja iz Beča traje otprilike 1 sat i 10 minuta, ovisno o prometu. Lokacija ima parkiralište s dovoljno prostora za sve goste.",
        he: "הגעה ברכב היא הדרך הנוחה ביותר להגיע למקום. הנסיעה מווינה לוקחת כשעה ו-10 דקות, תלוי בתנועה. המקום מציע חנייה עם מקום לכל האורחים.",
      },
      open: true,
    },
    {
      title: {
        en: "By Train",
        hr: "Vlakom",
        he: "ברכבת",
      },
      content: {
        en: "Arriving by train is a great way to enjoy the beautiful Austrian countryside. The train ride from Vienna to the nearest station (in Melk) takes approximately 1 hour. The venue can then be reached with taxi and is located 10 minutes from the station.",
        hr: "Dolazak vlakom je odličan način da uživate u prekrasnom austrijskom krajoliku. Vlak iz Beča do najbliže stanice (u Melku) traje otprilike 1 sat. Do lokacija se može doći taksijem i udaljena je 10 minuta od stanice.",
        he: "הגעה ברכבת היא דרך נהדרת ליהנות מנופי אוסטריה היפים. הנסיעה ברכבת מווינה לתחנה הקרובה ביותר (במלק) לוקחת כשעה. מהתחנה ניתן להגיע למקום במונית והמרחק הוא 10 דקות מהתחנה.",
      },
    },
    {
      title: {
        en: "By Shuttle",
        hr: "Shuttleom",
        he: "בשאטל",
      },
      content: {
        en: "We will provide a shuttle service from Vienna to the venue. The shuttle will depart from the city center and take approximately 1 hour and 10 minutes to reach the venue. If you wish to use the shuttle please let us know in your RSVP. We will be providing additional information closer to the date.",
        hr: "Dostavit ćemo uslugu shuttlea iz Beča do lokacije. Shuttle će polaziti iz centra grada i trebat će mu otprilike 1 sat i 10 minuta da stigne do lokacije. Ako želite koristiti shuttle, molimo vas da nam javite u vašoj potvrdi dolaska. Dostavit ćemo dodatne informacije bliže datumu.",
        he: "אנחנו נארגן שירות הסעה מווינה למקום, והנסיעה תארך כשעה ו-10 דקות. במידה ותרצו מקומות בשאטל, אנא ציינו זאת באישור ההגעה שלכם. מידע נוסף יישלח בהמשך."
      },
    },
  ];

  useEffect(() => {
    if (wrapper.current) {
      wrapper.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [wrapper, location]);

  return (
    <div
      ref={wrapper}
      className="relative bg-main-light md:mt-10 pb-4 px-0 sm:px-6 lg:pb-28 lg:px-8"
    >
      <div className="relative max-w-[90%] md:max-w-4xl mx-auto border-t pt-12">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="w-full flex items-center justify-center mb-2">
            <FlowerIcon icon={flowers["F8"]} />
          </div>
          <div className="mt-2 w-full">
            <span className="superheadline text-[16px] md:text-xl ltr:font-davidlibre rtl:font-bonanova w-max mx-auto text-main-yellow flex items-center relative uppercase">
              {copy.SUPER[lang]}
            </span>
          </div>
          <h2 className="mt-4 text-4xl md:text-5xl ltr:font-davidlibre rtl:font-bonanova font-medium">
            <span className="block text-main-green">{copy.TITLE[lang]}</span>
          </h2>
          <div className="my-7 px-8 md:px-0 w-full md:w-[440px] mx-auto text-center text-main text-lg">
            {copy.SUBTITLE[lang]}
          </div>
        </div>
      </div>
      <div className="relative max-w-[90%] md:max-w-4xl mx-auto pt-12">
        <div className="mx-auto w-full max-w-2xl rounded-2xl bg-white p-2">
          {options.map((option, index) =>
            option.open ? (
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="ltr:font-davidlibre rtl:font-bonanova transition-all flex w-full justify-between rounded-lg bg-main-tint px-4 py-2 text-left text-xl font-medium text-main hover:bg-main-yellow/15 focus:outline-none focus-visible:ring">
                      <span>{option.title[lang]}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-main-green mt-1 transition-transform`}
                      />
                    </Disclosure.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="px-4 pb-2 pt-4 text-[16px] text-gray-00">
                        {option.content[lang]}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ) : (
              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="ltr:font-davidlibre rtl:font-bonanova transition-all flex w-full justify-between rounded-lg bg-main-tint px-4 py-2 text-left text-xl font-medium text-main hover:bg-main-yellow/15 focus:outline-none focus-visible:ring">
                      <span>{option.title[lang]}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-main-green mt-1 transition-transform`}
                      />
                    </Disclosure.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="px-4 pb-2 pt-4 text-[16px] text-gray-00">
                        {option.content[lang]}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            )
          )}
        </div>
      </div>
    </div>
  );
};
