import { useLocation } from "react-router-dom";
import { FlowerIcon, flowers } from "../Components/CustomIcon";
import { useRef, useEffect } from "react";
import { CopyItem } from "../types";
import { useMainContext } from "../Context/MainContext";

const hotelOptions = [
  {
    title: "Hotel Schloss Dürnstein",
    image: "/images/staying/hotel-schloss-durnstein.jpeg",
    stars: 5,
    link: "https://www.booking.com/hotel/at/schloss-durnstein.html",
    viewOn: "Booking.com",
    perNight: 320,
    distance: 31,
  },
  {
    title: "Barock-Landhof Burkhardt",
    image: "/images/staying/barock-landhof.jpeg",
    stars: 4,
    link: "https://www.booking.com/hotel/at/barock-landhof-burkhardt.html",
    viewOn: "Booking.com",
    perNight: 295,
    distance: 19,
  },
  {
    title: "Donauhof",
    image: "/images/staying/donauhof.jpeg",
    stars: 4,
    link: "https://www.booking.com/hotel/at/donauhof.html",
    viewOn: "Booking.com",
    perNight: 170,
    distance: 23,
  },
  {
    title: "Weinhaus Nigl",
    image: "/images/staying/wienhauf-nigl.jpeg",
    stars: 4,
    link: "https://booking.com/hotel/at/weinhaus-nigl.html",
    viewOn: "Booking.com",
    perNight: 225,
    distance: 46,
  },
  {
    title: "Hotel-Restaurant Kirchenwirt",
    image: "/images/staying/kirchenwirt.jpeg",
    stars: 4,
    link: "https://booking.com/hotel/at/restaurant-kirchenwirt.html",
    viewOn: "Booking.com",
    perNight: 241,
    distance: 24,
  },
  {
    title: "Hotel Schachner",
    image: "/images/staying/schachner.jpeg",
    stars: 4,
    link: "https://www.booking.com/hotel/at/schachner.html",
    viewOn: "Booking.com",
    perNight: 375,
    distance: 14,
  },
  {
    title: "Hotel Restaurant zur Post",
    image: "/images/staying/rest-zur-post.jpeg",
    stars: 4,
    link: "https://www.booking.com/hotel/at/restaurant-zur-post.html",
    viewOn: "Booking.com",
    perNight: 150,
    distance: 10,
  },
  {
    title: "Smile Apartments",
    image: "/images/staying/smile-apartment.jpeg",
    stars: 4,
    link: "https://www.booking.com/hotel/at/smile-apartments-art-collection.html",
    viewOn: "Booking.com",
    perNight: 232,
    distance: 34,
  },
  {
    title: "Hotel Richard Löwenherz",
    image: "/images/staying/lowenherz.jpeg",
    stars: 4,
    link: "https://www.booking.com/hotel/at/romantik-richard-lowenherz.html",
    viewOn: "Booking.com",
    perNight: 226,
    distance: 30,
  },
  {
    title: "Donau-Rad-Hotel",
    image: "/images/staying/donau-red-hotel.jpeg",
    stars: 4,
    link: "https://www.booking.com/hotel/at/wachauerhof-renner-gmbh.html",
    viewOn: "Booking.com",
    perNight: 107,
    distance: 11,
  },
  {
    title: "Renaissancehof Wieser",
    image: "/images/staying/renaissancehof.jpeg",
    stars: 3,
    link: "https://www.booking.com/hotel/at/fresken-suite-im-renaissancehaus.html",
    viewOn: "Booking.com",
    perNight: 240,
    distance: 22,
  },
  {
    title: "Haus Lindenhofer",
    image: "/images/staying/hauslindenhofer.jpeg",
    stars: 3,
    link: "https://www.booking.com/hotel/at/haus-lindenhofer.html",
    viewOn: "Booking.com",
    perNight: 150,
    distance: 6,
  },
  {
    title: "Mathilde",
    image: "/images/staying/mathilde.jpeg",
    stars: 3,
    link: "https://www.booking.com/hotel/at/mathilde.html",
    viewOn: "Booking.com",
    distance: 20,
  },
  {
    title: "Weingut und Gästehaus Berger",
    image: "/images/staying/gh-berger.jpeg",
    stars: 3,
    link: "https://www.booking.com/hotel/at/weingut-und-gastehaus-berger.html",
    viewOn: "Booking.com",
    perNight: 175,
    distance: 43,
  },
  {
    title: "Ferienwohnungen Hartl",
    image: "/images/staying/hartl.jpeg",
    stars: 3,
    link: "https://www.booking.com/hotel/at/ferienwohnungen-hartl-spitz.html",
    viewOn: "Booking.com",
    perNight: 100,
    distance: 19,
  },
  {
    title: "Frühstückspension Porranzl",
    image: "/images/staying/porranzl.jpeg",
    stars: 3,
    link: "https://www.booking.com/hotel/at/fra1-4hsta1-4ckspension-porranzl.html",
    viewOn: "Booking.com",
    perNight: 100,
    distance: 21,
  },
  {
    title: "Mariandls Appartement",
    image: "/images/staying/mariandls.jpeg",
    stars: 3,
    link: "https://www.booking.com/hotel/at/mariandls-appartement-amp-mehr.html",
    viewOn: "Booking.com",
    perNight: 165,
    distance: 20,
  },
  {
    title: "Schlossnähe Artstetten",
    image: "/images/staying/schlossnahe.jpeg",
    stars: 3,
    link: "https://www.booking.com/hotel/at/urlaub-in-schlossnahe-artstetten1.html",
    viewOn: "Booking.com",
    perNight: 66,
    distance: 11,
  },
  {
    title: "Willi's Bauernhof",
    image: "/images/staying/bauernhof.jpeg",
    stars: 3,
    link: "https://www.booking.com/hotel/at/willi-39-s-bauernhof.html",
    viewOn: "Booking.com",
    perNight: 93,
    distance: 6,
  },
];

const Stars = ({ count }: { count: number }) => {
  return (
    <div className="flex items-center -ml-0.5">
      {[...Array(count)].map((_, index) => (
        <svg
          className="text-yellow-400 h-5 w-5 flex-shrink-0"
          x-state-description='undefined: "text-yellow-400", undefined: "text-gray-200"'
          x-description="Heroicon name: solid/star"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
        </svg>
      ))}
    </div>
  );
};
export const Staying = () => {
  const { lang } = useMainContext();
  const wrapper = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const copy: CopyItem = {
    SUPER: {
      en: "Plan Your",
      hr: "Planirajte svoj",
      he: "איפה לישון",
    },
    STAY: {
      en: "Stay",
      hr: "Boravak",
      he: "אפשרויות לינה",
    },
    SUBTITLE: {
      en: "Whether you're looking to stay and explore the Wachau Valley or you just want a place to rest after the wedding, the area has a variety of options.",
      hr: "Tražite li smještaj i atrakcije Wachau doline ili samo želite mjesto za odmor nakon vjenčanja, područje nudi različite mogućnosti.",
      he: "בין אם אתם רוצים להשאר ולטייל בעמק וואכאו או רק מחפשים מקום לנוח אחרי החתונה, האזור מציע מגוון אפשרויות.",
    },
    DISTANCE: {
      en: "Driving Distance to Venue: ",
      hr: "Udaljenost od lokacije vjenčanja: ",
      he: "מרחק נסיעה לאירוע: ",
    },
    PRICE: {
      en: "Avg. Price Per Night: ",
      hr: "Prosječna cijena noćenja: ",
      he: "מחיר ממוצע ללילה: ",
    },
    VIEWMORE: {
      en: "View on Booking.com",
      hr: "Pogledaj na Booking.com",
      he: "צפה ב-Booking.com",
    },
    MINUTE: {
      en: " min.",
      hr: " min.",
      he: " דק'",
    },
  };

  useEffect(() => {
    if (wrapper.current) {
      wrapper.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [wrapper, location]);

  return (
    <div
      ref={wrapper}
      className="relative bg-main-light md:mt-10 pb-4 px-0 sm:px-6 lg:pb-28 lg:px-8"
    >
      <div className="relative max-w-[90%] md:max-w-4xl mx-auto border-t pt-12">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="w-full flex items-center justify-center mb-2">
            <FlowerIcon icon={flowers["F5"]} />
          </div>
          <div className="mt-2 w-full">
            <span className="superheadline text-[16px] md:text-xl ltr:font-davidlibre rtl:font-bonanova w-max mx-auto text-main-yellow flex items-center relative uppercase">
              {copy.SUPER[lang]}
            </span>
          </div>
          <h2 className="mt-4 text-4xl md:text-5xl ltr:font-davidlibre rtl:font-bonanova font-medium">
            <span className="block text-main-green">
              {copy.STAY[lang]}
            </span>
          </h2>
          <div className="my-7 px-8 md:px-0 w-full md:w-[440px] mx-auto text-center text-main text-lg">
            {copy.SUBTITLE[lang]}
          </div>
        </div>
      </div>
      <div className="mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4 lg:max-w-7xl lg:px-8 mt-16">
        <ol className="-mx-3 grid grid-cols-1 gap-y-10 lg:grid-cols-3 lg:text-center xl:-mx-12">
          {hotelOptions.map((option, index) => (
            <li className="relative grid auto-rows-min grid-cols-1 items-center gap-8 px-3 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-1 xl:px-12">
              <div className="relative h-48 overflow-hidden rounded-lg shadow-lg sm:h-60 lg:h-40">
                <div className="absolute inset-0 flex items-center justify-center bg-[radial-gradient(#2C313D_35%,#000)]">
                  <img alt="" src={option.image} />
                </div>
              </div>
              <div className="ltr:text-left rtl:text-right">
                <h3 className="text-2xl mb-2">{option.title}</h3>
                <Stars count={option.stars} />
                <div className="mt-3 mb-3 text-[16px] text-slate-700">
                  <div>
                    {copy.DISTANCE[lang]}
                    <span className="font-semibold">
                      {option.distance} {copy.MINUTE[lang]}
                    </span>
                  </div>
                  <div>
                    {copy.PRICE[lang]}
                    <span className="font-semibold">€{option.perNight}</span>
                  </div>
                </div>
                <a
                  href={option.link}
                  target="_blank"
                  rel="noreferrer"
                  className="relative viewmore mb-1 mr-auto text-left text-main/90 hover:text-main/80 cursor-pointer text-base"
                >
                  {copy.VIEWMORE[lang]}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};
