import "./App.css";
import { Lang } from "./types";
import { Toast } from "./Components/Toast";
import { Helmet } from "react-helmet";
import { Footer } from "./Components/Footer";
import { Header } from "./Components/Header";
import { Outlet, useParams } from "react-router-dom";
import { MainContextProvider } from "./Context/MainContext";

const App = () => {
  const { lang } = useParams();

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Bona+Nova:wght@400;700&family=David+Libre:wght@400;500;700&family=EB+Garamond:wght@400;500&family=Galdeano&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <MainContextProvider lang={(lang as Lang) ?? "en"}>
        <div className="bg-main-light relative">
          <main dir={lang === "he" ? "rtl" : "ltr"}>
            <Header />
            <div className="relative overflow-hidden z-40 font-bonanova">
              <Outlet />
              <Footer />
              <Toast />
            </div>
          </main>
        </div>
      </MainContextProvider>
    </>
  );
};

export default App;
