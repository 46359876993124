import { TransportOption } from "../types";

export const transportOptions: TransportOption[] = [
  {
    id: 1,
    name: "By Car",
    title: {
      en: "By Car",
      hr: "Autom",
      he: "ברכב",
    },
    description: {
      en: "There are plenty of parking spots available on-site",
      hr: "Na raspolaganju je dovoljno parkirnih mjesta",
      he: "יש המון חניות פנויות באתר",
    },
  },
  {
    id: 2,
    name: "By Train",
    title: {
      en: "By Train",
      hr: "Vlakom",
      he: "ברכבת",
    },
    description: {
      en: "The nearby train station can only be reached by taxi",
      hr: "Do obližnje željezničke stanice može se doći samo taksijem",
      he: "תחנת הרכבת הקרובה נגישה רק במונית",
    },
  },
  {
    id: 3,
    name: "I need transportation",
    title: {
      en: "I need transportation",
      hr: "Treba mi prijevoz",
      he: "אני זקוק להסעה",
    },
    description: {
      en: "We will provide you with more information in May",
      hr: "Dostavit ćemo vam više informacija u svibnju",
      he: "נשלח לך עוד מידע במאי",
    },
  },
];