import { useState, useEffect } from "react";
import { Breakpoint, Screen } from "../types";

export const useBreakpoint = (): Breakpoint => {
  const [size, setSize] = useState<{ h: number; w: number }>(() => {
    return { h: window.innerHeight, w: window.innerWidth };
  });
  const [screen, setScreen] = useState<Screen>(() => {
    return window.innerWidth > 768 ? "desktop" : "mobile";
  });

  const update = () => {
    const h = window.innerHeight;
    const w = window.innerWidth;
    setSize({ h, w });
    setScreen(window.innerWidth > 768 ? "desktop" : "mobile");
  };

  useEffect(() => {
    window.addEventListener("resize", update);
    window.addEventListener("load", update);

    return () => {
      window.removeEventListener("resize", update);
      window.removeEventListener("load", update);
    };
  }, []);

  return { size, screen };
};
