import ReactDOM from "react-dom/client";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import App from "./App";
import { Arriving } from "./Pages/Arriving";
import { Exploring } from "./Pages/Exploring";
import { FAQ } from "./Pages/FAQ";
import { Home } from "./Pages/Home";
import { Staying } from "./Pages/Staying";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <></>,
    children: [
      {
        path: "*",
        element: <Navigate to="/" replace state={{ from: "/*" }} />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/:lang",
        element: <Home />,
      },
      {
        path: "/:lang/arriving",
        element: <Arriving />,
      },
      {
        path: "/:lang/staying",
        element: <Staying />,
      },
      {
        path: "/:lang/exploring",
        element: <Exploring />,
      },
      {
        path: "/:lang/faq",
        element: <FAQ />,
      },
      {
        path: "/:lang/rsvp",
        element: <Home />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
