import clsx from "clsx";

export const drawings = {
  "Butterfly (drawings set)": "\ue900",
  "Butterfly-Flipped (drawings set)": "\ue901",
  "Champagne (drawings set)": "\ue902",
  "Champagne-Flipped (drawings set)": "\ue903",
  "Decor (drawings set)": "\ue904",
  "Drink (drawings set)": "\ue905",
  "Envelope (drawings set)": "\ue906",
  "Flower-1 (drawings set)": "\ue907",
  "Flower-10 (drawings set)": "\ue913",
  "Flower-10-Rotated-Left (drawings set)": "\ue911",
  "Flower-10-Rotated-Right (drawings set)": "\ue912",
  "Flower-2 (drawings set)": "\ue908",
  "Flower-2-Flipped (drawings set)": "\ue909",
  "Flower-3 (drawings set)": "\ue90a",
  "Flower-4 (drawings set)": "\ue90b",
  "Flower-5 (drawings set)": "\ue90c",
  "Flower-5-Flipped (drawings set)": "\ue90d",
  "Flower-6 (drawings set)": "\ue90e",
  "Flower-8 (drawings set)": "\ue90f",
  "Flower-9 (drawings set)": "\ue910",
  "HandFlower (drawings set)": "\ue914",
  "HandFlower-Flipped (drawings set)": "\ue915",
  "HandHeart (drawings set)": "\ue916",
  "HandHeart-1 (drawings set)": "\ue917",
  "HandHeart-Flipped (drawings set)": "\ue918",
  "Heart (drawings set)": "\ue919",
  "Kiss (drawings set)": "\ue91a",
  "Leaf (drawings set)": "\ue91b",
  "Leaf-Rotated-Left (drawings set)": "\ue91c",
  "Leaf-Rotated-right (drawings set)": "\ue91d",
  "Leaves-1 (drawings set)": "\ue91e",
  "Leaves-1-Rotated-Left (drawings set)": "\ue91f",
  "Leaves-1-Rotated-Right (drawings set)": "\ue920",
  "Leaves-2 (drawings set)": "\ue921",
  "Leaves-2-Rotated-Left (drawings set)": "\ue922",
  "Leaves-2-Rotated-Right (drawings set)": "\ue923",
  "Leaves-3 (drawings set)": "\ue924",
  "Leaves-4 (drawings set)": "\ue925",
  "Leaves-4-Rotated-Left (drawings set)": "\ue926",
  "Leaves-4-Rotated-Right (drawings set)": "\ue927",
  "Leaves-5 (drawings set)": "\ue928",
  "Leaves-5-Flipped (drawings set)": "\ue929",
  "Leaves-5-Rotated (drawings set)": "\ue92c",
  "Leaves-5-Rotated-Left (drawings set)": "\ue92a",
  "Leaves-5-Rotated-Right (drawings set)": "\ue92b",
  "Lunch (drawings set)": "\ue92d",
  "Paperplane (drawings set)": "\ue92e",
  "Paperplane-1 (drawings set)": "\ue930",
  "Paperplane-1-Flipped (drawings set)": "\ue92f",
  "Paperplane-Flipped (drawings set)": "\ue931",
  "Ring (drawings set)": "\ue932",
  "Toast (drawings set)": "\ue933",
  "Bride (drawings set)": "\ue934",
  "Bride-1 (drawings set)": "\ue935",
  "Z-Corona (drawings set)": "\ue936",
  "Z-Distance-2 (drawings set)": "\ue937",
  "Z-Distance-4 (drawings set)": "\ue938",
  "Z-Facemask (drawings set)": "\ue939",
  "Z-Hand-soap (drawings set)": "\ue93a",
  "Z-Photo-camera (drawings set)": "\ue93b",
};

export const flowers = {
  "Branch-Right (flowers)": "\ue900",
  "Branch (flowers)": "\ue901",
  "Dendelion-Left (flowers)": "\ue902",
  "Dendelion-Right (flowers)": "\ue903",
  "Dendelion (flowers)": "\ue904",
  "Flower-Left (flowers)": "\ue905",
  "Flower-Right (flowers)": "\ue906",
  "Flower (flowers)": "\ue907",
  "Flower-1 (flowers)": "\ue908",
  "Leaves-Left (flowers)": "\ue909",
  "Leaves-Right (flowers)": "\ue90a",
  "Leaves (flowers)": "\ue90b",
  "Leaves-1-Left (flowers)": "\ue90c",
  "Leaves-1-Right (flowers)": "\ue90d",
  "Leaves-1 (flowers)": "\ue90e",
  "Leaves-2-Left (flowers)": "\ue90f",
  "Leaves-2-Right (flowers)": "\ue910",
  "Leaves-2 (flowers)": "\ue911",
  "Leaves-3-Left (flowers)": "\ue912",
  "Leaves-3-right (flowers)": "\ue913",
  "Leaves-3 (flowers)": "\ue914",
  "Rose-Left (flowers)": "\ue915",
  "Rose-Right (flowers)": "\ue916",
  "Rose (flowers)": "\ue917",
  "Branch-Left (flowers)": "\ue918",
  "Dandelion (flowers)": "\ue919",
  "Floower-horiz (flowers)": "\ue91a",
  "Flower-bloom (flowers)": "\ue91b",
  "flower-left (flowers)": "\ue91c",
  "Flower-right (flowers)": "\ue91d",
  "Flower-ver (flowers)": "\ue91e",
  "Leaf-horiz (flowers)": "\ue91f",
  "Leaf-ver (flowers)": "\ue920",
  "Leaves-horiz (flowers)": "\ue921",
  "Leaves-ver (flowers)": "\ue922",
  "F1": "\ue900",
  "F2": "\ue901",
  "F3": "\ue902",
  "F4": "\ue903",
  "F5": "\ue904",
  "F6": "\ue905",
  "F7": "\ue906",
  "F8": "\ue907",
  "F9": "\ue908",
  "F10": "\ue909",
  "F11": "\ue90a",
  "F12": "\ue90b",
  "F13": "\ue90c",
  "F14": "\ue90d",
  "F15": "\ue90e",
  "F16": "\ue90f",
  "F17": "\ue910",
  "F18": "\ue911",
  "F19": "\ue912",
  "F20": "\ue913",
  "F21": "\ue914",
  "F22": "\ue915",
  "F23": "\ue916",
  "F24": "\ue917",
  "F25": "\ue918",
  "F26": "\ue919",
  "F27": "\ue91a",
  "F28": "\ue91b",
  "F29": "\ue91c",
  "F30": "\ue91d",
  "F31": "\ue91e",
  "F32": "\ue91f",
  "F33": "\ue920",
  "F34": "\ue921",
  "F35": "\ue922",
};

export const DrawingIcon = ({
  icon,
  size = "text-[4rem]",
  color = "#749b5d",
  hoverColor = "#baa37b",
}: {
  icon: string;
  size?: string;
  color?: string;
  hoverColor?: string;
}) => {
  return (
    <div className="inline-flex items-center justify-center mx-auto py-0">
      <span
        data-ico-drawings={icon}
        className={clsx(
          "flex flex-row flex-nowrap justify-start items-center",
          size,
        )}
        style={{
          color: color,
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.color = hoverColor;
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.color = color;
        }}
      ></span>
    </div>
  );
};

export const FlowerIcon = ({
  icon,
  size = "text-[4rem]",
  color = "#749b5d",
  hoverColor = "#baa37b",
}: {
  icon: string;
  size?: string;
  color?: string;
  hoverColor?: string;
}) => {
  return (
    <div className="inline-flex items-center justify-center mx-auto py-0">
      <span
        data-ico-flowers={icon}
        className={clsx(
          "flex flex-row flex-nowrap justify-start items-center",
          size,
        )}
        style={{
          color: color,
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.color = hoverColor;
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.color = color;
        }}
      ></span>
    </div>
  );
};
